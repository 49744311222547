<template>
  <v-app id="main-container">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg4>
          <v-card class="elevation-3 pa-5" v-if="!submitted">
            <div class="text-center mb-5">
              <img
                src="./../assets/images/logo.png"
                class="logo"
                alt="InsightzClub"
              />
            </div>
            <v-card-title class="text-h5 text-center mb-4">
              Join our community
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.name"
                label="Full Name"
                outlined
                dense
                required
                v-validate="'required'"
                data-vv-name="name"
                :error-messages="errors.collect('name')"
              ></v-text-field>
              <v-text-field
                v-validate="'required|email'"
                data-vv-name="email"
                :error-messages="errors.collect('email')"
                v-model="user.email"
                label="Email Address"
                outlined
                dense
                type="email"
                required
              ></v-text-field>
              <v-text-field
                v-model="user.phoneNumber"
                label="Contact Number"
                outlined
                dense
                required
                v-validate="'required|numeric'"
                data-vv-name="phoneNumber"
                :error-messages="errors.collect('phoneNumber')"
              ></v-text-field>
              <v-text-field
                v-model="user.country"
                label="Country"
                outlined
                dense
                required
                v-validate="'required'"
                data-vv-name="country"
                :error-messages="errors.collect('country')"
              ></v-text-field>
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="user.dob"
                    label="Date of Birth"
                    outlined
                    dense
                    required
                    v-validate="'required'"
                    data-vv-name="dob"
                    :error-messages="errors.collect('dob')"
                    readonly
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  color="primary"
                  no-title
                  scrollable
                  v-model="user.dob"
                >
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startDateMenu.save(Date(startDate))"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="user.referralCode"
                label="Referral Code"
                outlined
                dense
              ></v-text-field>
            </v-form>
            <div class="text-center">
              <v-btn @click="submitData" dark color="#149B85" class="mt-5"
                >Submit</v-btn
              >
            </div>
          </v-card>
          <v-card v-else class="success-card elevation-3 pa-5">
            <v-icon class="success-icon">mdi-check-circle-outline</v-icon>
            <div class="success-message">
              Thank you for joining our community!
            </div>
            <div class="text-center">
              <v-btn
                @click="submitted = false"
                dark
                color="#149B85"
                class="mt-5"
                >Go Back</v-btn
              >
            </div>
          </v-card>
          <div class="text-center mt-5">
            <h1 class="catchy-text mt-10">
              Download our app now and start receiving incentives by
              participating in surveys
            </h1>
            <div class="d-flex justify-center">
              <a
                href="https://apps.apple.com/us/app/insightzclub/id1469156792?ls=1"
                target="_blank"
              >
                <img
                  src="../../public/appStore.png"
                  class="store-logo"
                  alt="App Store"
                  width="150"
                />
              </a>
              <div class="gap"></div>
              <a
                href="https://play.google.com/store/apps/details?id=com.insightzclub&pli=1"
                target="_blank"
              >
                <img
                  src="../../public/playStore.png"
                  class="store-logo"
                  alt="Play Store"
                  width="150"
                />
              </a>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { DataService as BuyerDataService } from "./../services/BuyerDataService";

const buyerDataService = new BuyerDataService();

export default {
  data() {
    return {
      valid: true,
      startDate: null,
      startDateMenu: false,
      user: {},
      submitted: false,
    };
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$setLoader();
          buyerDataService
            .joinOurCommunity(this.user)
            .then(() => {
              this.submitted = true;
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
            })
            .finally(() => {
              this.$clearLoader();
            });
        }
      });
    },
  },
};
</script>

<style scoped>
#main-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background-color: #f0f2f5; /* Set your desired background color */
}

.logo {
  max-width: 100%;
  height: auto;
}

.v-card {
  max-width: 400px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}
.gap {
  width: 20px;
}

.catchy-text {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}
</style>
